<template>
  <div class="informationmanagmentview">
    <h1 align="left">Information Management</h1>

    <div>
      <b-card no-body>
        <b-card-header header-tag="nav">
          <b-nav card-header tabs fill>
            <b-nav-item :to="{ name: 'PersonalInformationRoute' }" exact active-class="active">Personal Information</b-nav-item>
            <b-nav-item :to="{ name: 'PrivacyPreferencesRoute' }" active-class="active">Privacy Preferences</b-nav-item>
          </b-nav>
        </b-card-header>
        <b-card-body class="text-center">
          <b-card-text>
            <router-view></router-view>
          </b-card-text>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */

export default {
  name: "InformationManagementView"
}
</script>
